import AllRequestsTable from '../tables/AllRequestsTable';

export default function AllRequestsTab() {
    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem]">
                <div className="white_card flex-col">
                    <span className="heading_4 mt-[1rem]">List of requests</span>
                    <div className="w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]">
                        

                        <AllRequestsTable />
                    </div>
                </div>
            </div>
        </>
    );
}
