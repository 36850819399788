import { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { validMemberPaths } from './data';
import { useSelector } from 'react-redux';
import useNetworkStatus from './custom_hooks/useNetwork';
import NoNetwork from './components/NoNetwork';

export default function PathValidator({ children }) {
    const location = useLocation();
    const { isOnline } = useNetworkStatus();

    const { memberRole } = useSelector((state) => state.auth);

    const isPathValid = validMemberPaths.includes(location.pathname);

    useEffect(() => {}, [isOnline]);

    useEffect(() => {
        if (!isPathValid && memberRole === 'MEMBER') {
            <Navigate to="/404" replace />;
        }
    }, [isPathValid, memberRole]);

    if (!isOnline) return <NoNetwork />

    if (memberRole !== 'MEMBER') return children;

    return isPathValid ? children : <Navigate to="/404" replace />;
}
