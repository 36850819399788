import { Link, useNavigate } from 'react-router-dom';
import image10 from '../../assets/svg/ndovuLogoNew.svg';
import NavbarComponent from '../landing_page/landing_page_components/NavbarComponent';

export default function PrivacyPolicy() {
    const navigate = useNavigate();

    return (
        <>
            <div className="pt-[1.88rem] px-[2.5rem] w-full">
                <NavbarComponent handleNavigate={() => navigate('/')} />
            </div>
            <div className="flex flex-col w-full px-[5rem] py-[3rem]">
                <span className="heading_1">Privacy Policy</span>
                <span className="paragraph_1 mt-[1.5rem]">Effective Date: 3/27/2025</span>

                <span>
                    Thank you for using Chamify. Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use our
                    mobile application.
                </span>

                <div className="flex flex-col mt-[1.5rem] gap-[.62rem]">
                    <span className="heading_5 mb-[.52rem]">1. Information We Collect</span>
                    <span className="label_2">We collect the following types of information:</span>
                    <span className="paragraph_1">
                        ● Personal Information: When you create an account, we may collect your name, email address, phone number, and profile picture.
                    </span>
                    <span className="paragraph_1">
                        ● Usage Data: We collect information about how you use the app, including app interactions, preferences, and features accessed.
                    </span>
                    <span className="paragraph_1">● Device Information: We may collect device model, operating system, and unique identifiers to improve app performance.</span>
                    <span className="paragraph_1"> ● Location Data (if enabled): We collect location data to enhance app</span>
                </div>

                <div className="flex flex-col mt-[1.5rem] gap-[.62rem]">
                    <span className="heading_5 mb-[.52rem]">2. How We Use Your Information</span>

                    <span className="label_2">We use the collected data to:</span>

                    <span className="paragraph_1">● Provide and improve our services.</span>

                    <span className="paragraph_1">● Personalize user experience.</span>
                    <span className="paragraph_1">● Send important notifications and updates.</span>
                    <span className="paragraph_1">● Detect and prevent fraudulent activities.</span>
                    <span className="paragraph_1">● Ensure security and compliance with legal requirements.</span>
                </div>

                <div className="flex flex-col mt-[1.5rem] gap-[.62rem]">
                    <span className="heading_5 mb-[.52rem]">3. Data Sharing & Third-Party Services</span>

                    <span className="label_2">We do not sell your personal data. However, we may share information with:</span>

                    <span className="paragraph_1">● Service Providers: Third-party vendors who help with app functionality, such as cloud storage and analytics.</span>

                    <span className="paragraph_1">● Legal Authorities: If required by law, we may share your data with law enforcement.</span>
                </div>

                <div className="flex flex-col mt-[1.5rem] gap-[.62rem]">
                    <span className="heading_5 mb-[.52rem]">4. Data Security</span>

                    <span className="label_2">
                        We implement industry-standard security measures to protect your data. However, no system is 100% secure, and we encourage users to take precautions when
                        sharing information.
                    </span>
                </div>

                <div className="flex flex-col mt-[1.5rem] gap-[.62rem]">
                    <span className="heading_5 mb-[.52rem]">5. Your Rights & Choices</span>

                    <span className="paragraph_1">● Access & Update: You can access and update your personal information in the app settings.</span>

                    <span className="paragraph_1">● Opt-out: You may disable location access or opt out of notifications at any time.</span>
                    <span className="paragraph_1">● Account Deletion: To delete your account and associated data, contact our support team.</span>
                </div>

                <div className="flex flex-col mt-[1.5rem] gap-[.62rem]">
                    <span className="heading_5 mb-[.52rem]">6. Changes to This Policy</span>

                    <span className="label_2">We may update this Privacy Policy from time to time. We will notify users of any significant changes through the app or email.</span>
                </div>

                <div className="flex flex-col mt-[1.5rem] gap-[.62rem]">
                    <span className="heading_5 mb-[.52rem]">7. Contact Us</span>

                    <span className="label_2">If you have any questions about this Privacy Policy, please contact us at:</span>

                    <span className="paragraph_1">Email: info@spa-limited.com</span>

                    <span className="paragraph_1">Website: https://chamify.africa/</span>
                </div>

                <span className="paragraph_2 mt-[3rem]"> Thank you for using Chamify!</span>
            </div>
        </>
    );
}
