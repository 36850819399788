import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Modal, Spin, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import info32 from '../../../assets/svg/Info32.svg';
import info from '../../../assets/svg/Info.svg';
import { fetchRates, handleResetrates, mpesaPushCustom } from '../../../features/money/moneySlice';
import { customToast, formatMoney } from '../../../utils';

export default function MakeContributionModal({ open, handleCancel, payObj, handleFetch }) {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const { moneying, ndovuRates } = useSelector((state) => state.money);

    const [statusOk, setstatusOk] = useState(false);
    const [amount, setamount] = useState(payObj?.schContAmount);

    async function handleFetchRates(val) {
        if (typeof val !== 'number') {
            val = 0; 
        }
        await dispatch(fetchRates(val));
    }

    const onFinish = async (data) => {
        data.accountNo = payObj?.schWalCode;
        data.amount = ndovuRates?.total

        if (data.amount < 1) {
            customToast({
                content: 'Amount cannot be less than 1',
                bdColor: 'error',
                id: 777777777777,
            });
            return;
            
        }        

        const res = await dispatch(mpesaPushCustom(data));

        if (res?.payload?.success) {
            await setstatusOk(true);
            await dispatch(handleResetrates())
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'An error has occured while processing your request',
                bdColor: 'error',
                id: 9999888877789,
            });
        }
    };

    async function handleClose() {
        await form.resetFields();
        await handleCancel();
        await setstatusOk(false);
        await dispatch(handleResetrates())
        await handleFetch();
    }

    useEffect(() => {
        if (payObj?.schContAmount) {
            handleFetchRates(amount);
        }
    }, [amount]);

    useEffect(() => {}, [ndovuRates]);

    useEffect(() => {
        setamount(payObj?.schContAmount);
    }, [payObj]);

    useEffect(() => {
        form.setFieldsValue({
            amount,
        });
    }, [amount, form]);

    return (
        <>
            <Modal centered className="custom-modal" title="Payment" open={open} footer={false}>
                <div className="flex justify-between items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]">
                    <span className="heading_4 !text-white">{'Make payment'}</span>
                    <button onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </div>

                <div className="px-[2.94rem] mt-[2.56rem]">
                    <div className="w-full flex flex-col">
                        {statusOk ? (
                            <>
                                <div
                                    style={{
                                        borderRadius: '0.5rem',
                                        border: '1px solid #3BBB6E',
                                        background: '#FFF',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
                                    }}
                                    className="w-full flex items-start gap-[.5rem] p-[1rem]"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986"
                                            stroke="#3BBB6E"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path d="M22 4L12 14.01L9 11.01" stroke="#3BBB6E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    <div className="flex flex-col gap-[.5rem]">
                                        <span className="modal_success_header">Success</span>
                                        <span className="modal_success_text">MPESA STK push notification has been initialized. Please enter MPESA PIN to complete transaction.</span>
                                    </div>
                                </div>

                                <div className="flex items-center justify-center mt-[5rem] w-full">
                                    <div className="w-[4.4375rem] flex items-center gap-[.5rem]">
                                        <button onClick={handleClose} className="modal_btn_dark" type="button">
                                            Okay
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <Form
                                    layout="vertical"
                                    ref={formRef}
                                    name="makeContributionModal"
                                    onFinish={onFinish}
                                    style={{
                                        maxWidth: '100%',
                                    }}
                                    initialValues={{
                                        amount,
                                        phone: user?.phone,
                                    }}
                                    form={form}
                                >
                                    <div className="flex flex-col w-full mb-[2.19rem]">
                                        <span className="label_2">
                                            Total payable amount is {formatMoney(ndovuRates?.total, 'KES')} <sup className="text-[green]">+ {formatMoney(ndovuRates?.commission, 'KES')}</sup>
                                        </span>
                                        <span className="label_2">Submit/edit the following details to make a payment request. </span>
                                    </div>

                                    <Form.Item
                                        label="Amount (KES)"
                                        name="amount"
                                        extra={<span className="text-[green]">Total {`${formatMoney(ndovuRates?.total, 'KES')} inclusive of convenience fee`}</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Amount is required',
                                            },
                                        ]}
                                    >
                                        <InputNumber onChange={(val) => setamount(val)} min={1} className="input" />
                                    </Form.Item>

                                    <Form.Item
                                        label="M-PESA mobile number"
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'M-PESA number is required',
                                            },
                                        ]}
                                    >
                                        <Input className="input" />
                                    </Form.Item>

                                    <div className="w-full flex items-center gap-[.5rem] mt-[1.9rem]">
                                        <span>You will receive a prompt on your phone to complete the transaction. </span>
                                        <Tooltip
                                            arrow={false}
                                            // open={true}
                                            placement="bottom"
                                            title={
                                                <>
                                                    <div className="w-full flex flex-col p-[1rem] gap-[.5rem]">
                                                        <div className="flex items-start gap-[.5rem]">
                                                            <img src={info32} alt="info" />
                                                            <span className="toolTip_header">MPESA STK Push</span>
                                                        </div>

                                                        <span className="toolTip_text">MPESA STK push will be initialized on your mobile number. Enter your MPESA PIN to complete the transaction</span>
                                                    </div>
                                                </>
                                            }
                                        >
                                            <img src={info} alt="info" />
                                        </Tooltip>
                                    </div>

                                    <div className="flex items-center justify-end mt-[5rem] w-full">
                                        <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
                                            <button disabled={moneying} className="modal_btn_dark" type="submit">
                                                {moneying ? <Spin /> : 'Next'}
                                            </button>

                                            <button disabled={moneying} onClick={handleClose} className="modal_btn_light" type="button">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
}
