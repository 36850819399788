import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CardLoading from '../../../components/CardLoading';
import { fetchPendingSettlementAccounts, fetchSettlementAccounts } from '../../../features/fetch/fetchSlice';
import { capitalizeFirstLetter } from '../../../utils';
import RemoveSettlementAccountModal from '../../chama/modals/RemoveSettlementAccountModal';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import AddSettlementAccountModal from '../modals/AddSettlementAccountModal';

export default function SettlementAccountsTab() {
    const dispatch = useDispatch();
    const { open, handleCancel, handleOpen } = useModalToggle();
    const addSettlement = useModalToggle();

    const { loading, settlementAccounts, PendingsettlementAccounts } = useSelector((state) => state.fetch);
    const { activeChama } = useSelector((state) => state.obj);

    const [deleteObj, setdeleteObj] = useState({});

    function handleDelete(item) {
        setdeleteObj(item);
        handleOpen();
    }

    function handleClose() {
        setdeleteObj({});
        handleCancel();
    }

    async function handlefetch() {
        await dispatch(fetchSettlementAccounts(activeChama?.custId));
        await dispatch(fetchPendingSettlementAccounts());
    }

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
        handlefetch();
    }, []);

    if (loading) return <CardLoading />;

    return (
        <>
            <div className="w-full flex flex-col mt-[3.5rem]">
                <div className="flex flex-col h-auto">
                    <div className="w-full flex pb-[2.25rem]">
                        <div className="w-full flex flex-col gap-[.5rem]">
                            <span className="heading_6">Settlement accounts</span>
                            <span className="paragraph_2">View and update your settlement account details</span>
                        </div>
                    </div>
                </div>

                <span className="heading_6">Account details</span>

                <div className="w-full grid grid-cols-1 gap-[1rem] mt-[1.25rem]">
                    {settlementAccounts?.length ? (
                        settlementAccounts?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        padding: '1.5rem 1.25rem',
                                        borderRadius: '0.25rem',
                                        border: '1px solid var(--Tile-stroke, #E2E9EE)',
                                    }}
                                    className="w-full xl:w-fit xl:min-w-[43.375rem] flex justify-between items-center"
                                >
                                    <span className="paragraph_3">
                                        {item?.staType === 'BANK' ? item?.staBankName + ' | ' : 'MPESA |'} {item?.staAccountNumber} | {capitalizeFirstLetter(item?.staName)}
                                    </span>

                                    {/* <div className="flex items-center gap-[1.5rem]">
                                    <span className="paragraph_3">Edit</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M6 3L11 8L6 13" stroke="#343330" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div> */}
                                    <button type="button" onClick={() => handleDelete(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M20.25 4.5H16.5V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5H9.75C9.15326 1.5 8.58097 1.73705 8.15901 2.15901C7.73705 2.58097 7.5 3.15326 7.5 3.75V4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM9 3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5H9V3.75ZM18 19.5H6V6H18V19.5ZM10.5 9.75V15.75C10.5 15.9489 10.421 16.1397 10.2803 16.2803C10.1397 16.421 9.94891 16.5 9.75 16.5C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75V9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9C9.94891 9 10.1397 9.07902 10.2803 9.21967C10.421 9.36032 10.5 9.55109 10.5 9.75ZM15 9.75V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5C14.0511 16.5 13.8603 16.421 13.7197 16.2803C13.579 16.1397 13.5 15.9489 13.5 15.75V9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75Z"
                                                fill="#343330"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            );
                        })
                    ) : (
                        <span className="paragraph_2">
                            Add an account to settle your group funds to. <br />
                            You may add more than one account.
                        </span>
                    )}
                </div>

                <button type="button" onClick={() => addSettlement.handleOpen()} className="w-full lg:w-[35rem] flex items-center gap-[.5rem] mt-[2rem]">
                    <div className="w-[1.5rem] h-[1.5rem] flex justify-center items-center bg-blk rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 8L12 16M16 12L8 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </div>

                    <span className="role_btn_text">Add account</span>
                </button>

                {PendingsettlementAccounts?.length ? (
                    <>
                        <span className="heading_6 mt-[2.75rem]">Pending approvals</span>

                        <div className="w-full grid grid-cols-1 gap-[1rem] mt-[1.25rem]">
                            {PendingsettlementAccounts?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            padding: '1.5rem 1.25rem',
                                            borderRadius: '0.25rem',
                                            border: '1px solid var(--Tile-stroke, #E2E9EE)',
                                        }}
                                        className="w-full xl:w-fit xl:min-w-[43.375rem] flex justify-between items-center"
                                    >
                                        <span className="paragraph_3">
                                            {item?.staType === 'BANK' ? item?.staBankName + ' | ' : 'MPESA |'} {item?.staAccountNumber} | {capitalizeFirstLetter(item?.staName)}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                ) : null}
            </div>

            <RemoveSettlementAccountModal handleFetch={handlefetch} deleteObj={deleteObj} open={open} handleCancel={handleClose} />
            <AddSettlementAccountModal handleFetch={handlefetch} open={addSettlement.open} handleCancel={addSettlement.handleCancel} />
        </>
    );
}
