import { Dropdown } from 'antd';
import { useState } from 'react';
import FilterPanel from '../../components/FilterPanel';

export default function StatementFilter({ disabled, filterOptions, handleApplyFilters, handleClearFilters }) {
    const [open, setopen] = useState(false);

    function handleOpenChange() {
        setopen(!open);
    }

    const filterItems = [
        {
            key: 1,
            label: <FilterPanel title="Filter" filterOptions={filterOptions} onApplyFilters={handleApplyFilters} onClearFilters={handleClearFilters} />,
        },
    ];

    return (
        <>
            <div className="search">
                <Dropdown
                    disabled={!disabled}
                    overlayClassName="filter_dropdown"
                    menu={{
                        items: filterItems,
                    }}
                    open={open}
                    onOpenChange={handleOpenChange}
                    trigger={['click']}
                    placement="bottomLeft"
                >
                    <button className="w-full flex justify-between items-center">
                        <span className="flex flex-shrink-0 paragraph_2">Filter</span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path d="M8 17H24" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3 11H29" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13 23H19" stroke="#343330" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </Dropdown>
            </div>
        </>
    );
}
