import { Table } from 'antd';
import moment from 'moment';
import { capitalizeFirstLetter, formatMoney, getRandomColor } from '../../../utils';
import useFetchDispatch from '../../../custom_hooks/useFetchDispatch';
import { fetchSettlements } from '../../../features/money/moneySlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import TableLoading from '../../../components/TableLoading';

const columns = [
    {
        title: 'Date',
        dataIndex: 'withDrawCreatedDate',
        render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
    },
    {
        title: 'Amount',
        dataIndex: 'withDrawAmount',
        render: (item) => <span>{formatMoney(item, 'KES')}</span>,
    },
    {
        title: 'Particulars',
        dataIndex: 'withDrawParticular',
        render: (item) => <span>{capitalizeFirstLetter(item)}</span>,
    },
    {
        title: 'Recipient name',
        render: (item) => (
            <span>
                {item?.withDrawType === 'BANK' ? (
                    <>
                        <div className="w-full flex items-center gap-[.5rem]">
                            <img
                                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item?.withDrawBankName)}&background=${getRandomColor()}&color=fff`}
                                className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                                alt="avatar"
                            />
                            <span>{item?.withDrawBankName}</span>
                        </div>
                    </>
                ) : (
                    ' - '
                )}
            </span>
        ),
    },
    {
        title: 'Recipient account',
        render: (item) => <span>{item?.withDrawType === 'BANK' ? <>{item?.withDrawAccountNumber}</> : item?.withDrawMpesaNumber}</span>,
    },
    {
        title: 'Source account',
        dataIndex: 'withDrawCwCollection',
    },
    {
        title: 'Type',
        dataIndex: 'withDrawType',
    },
];

export default function PendingWithdrawalsApprovalTable() {
    const { isLoading } = useFetchDispatch(fetchSettlements);

    const { settlements } = useSelector((state) => state.money);

    let filteredArray = settlements?.filter((item) => item?.withDrawStatus === 'PENDING_APPROVAL');

    useEffect(() => {}, [settlements]);

    if (isLoading) return <TableLoading />;

    return (
        <>
            <Table
                scroll={{
                    x: 1300,
                }}
                rowKey="withDrawId"
                className="mt-[1.5rem] !w-full"
                pagination={false}
                columns={columns}
                dataSource={filteredArray}
            />
        </>
    );
}
