import { useSelector } from 'react-redux';
import Search from '../../components/Search';
import ChamaListCard from './cards/ChamListCard';
import { Link } from 'react-router-dom';
import useFetchDispatch from '../../custom_hooks/useFetchDispatch';
import { fetchChamas } from '../../features/fetch/fetchSlice';
import CardLoading from '../../components/CardLoading';
import { useEffect, useState } from 'react';
import useFilterArray from '../../custom_hooks/useFilterArray';
import { icon } from '../../utils';

export default function ChamaList() {
    const { isLoading } = useFetchDispatch(fetchChamas);

    const { chamas } = useSelector((state) => state.fetch);
    const { authLoading } = useSelector((state) => state.auth);


    const { filterArray, handleFilter } = useFilterArray(chamas);

    const [searchInput, setsearchInput] = useState('');
    const [loading, setloading] = useState(false)

    const handleLoading = (val) => setloading(val)

    const handleSearch = (val) => {
        setsearchInput(val);
        let filter = chamas?.filter((x) => String(x?.custName).toUpperCase().includes(String(val).toUpperCase()));
        handleFilter(filter);
    };

    useEffect(() => {
        if (searchInput.length === 0) {
            handleFilter(chamas);
        }
    }, [searchInput]);

    useEffect(() => {
        handleFilter(chamas);
    }, [chamas]);

    useEffect(() => {}, [filterArray]);

    if (isLoading || loading || authLoading) return <CardLoading />;

    return (
        <>
            <div className="flex flex-col w-full bg-white rounded-[1.25rem] h-full p-[2.5rem]">
                {chamas?.length ? (
                    <>
                        <div className="w-[16.8125rem]">
                            <Search searchInput={searchInput} handleSearch={handleSearch} text={'Search chama'} readOnly={true} />
                        </div>

                        <div className="flex items-center overflow-x-auto overflow-y-hidden h-auto w-full mt-[1.25rem] gap-[.44rem]">
                            {filterArray?.length ? (
                                filterArray?.map((item, index) => {
                                    return (
                                        <div className="h-full" key={index}>
                                            <ChamaListCard handleLoading={handleLoading} item={item} />
                                        </div>
                                    );
                                })
                            ) : (
                                <span>No record found</span>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="w-full h-full flex flex-col justify-center items-center py-[2.5rem]">
                            {icon}

                            <span className="heading_5 my-[1.25rem]">Welcome to Chamify Africa.</span>

                            <span className="paragraph_2">Get started by joining or creating a chama group</span>

                            <div className="w-[15rem] mt-[2rem]">
                                <div className="flex flex-col justify-center items-center h-full w-full mt-[1.25rem] gap-[.44rem]">
                                    <div className="w-fit">
                                        <Link to="/join-chama" className="cstm-btn">Join an existing chama</Link>

                                        <Link to="/new/chama-features" className="cstm-btn-2 mt-[1rem]">
                                            Create a chama
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
