import React, { useEffect, useState } from 'react';
import FilterSearch from './FilterSearch';
import { Checkbox, Collapse, DatePicker, Spin } from 'antd';
import { capitalize } from '../utils';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { handleFilters } from '../features/filters/filterSlice';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';

export default function FilterPanel({ title, filterOptions, onApplyFilters, onClearFilters }) {
    const dispatch = useDispatch();

    const { fiLoading, filters } = useSelector((state) => state.filter);
    const { activeChama } = useSelector((state) => state.obj);

    const [selectedFilters, setSelectedFilters] = useState(filters);
    const [dateRange, setDateRange] = useState({ from: filters?.dateRange?.from, to: filters?.dateRange?.to });
    const [searchInput, setsearchInput] = useState('');

    const handleSearch = (val) => {
        setsearchInput(val);
    };

    const handleFilterChange = (category, option) => {
        setSelectedFilters((prev) => {
            const updatedCategory = { ...prev[category] };

            if (option === 'All') {
                dispatch(handleFilters({ [category]: { All: true } }));
                return {
                    ...prev,
                    [category]: {
                        All: true,
                    },
                };
            } else {
                updatedCategory[option] = !updatedCategory[option];

                if (updatedCategory.All) {
                    updatedCategory.All = false;
                }

                dispatch(handleFilters({ [category]: updatedCategory }));

                return {
                    ...prev,
                    [category]: updatedCategory,
                };
            }
        });
    };

    const handleDateChange = (key, value) => {
        const newDateValue = moment(value?.$d).format('YYYY-MM-DD') || null;
        setDateRange((prev) => ({ ...prev, [key]: newDateValue }));

        dispatch(
            handleFilters({
                dateRange: {
                    ...dateRange,
                    [key]: newDateValue,
                },
            })
        );
    };

    const transformFilters = (filters) => {
        const transformedFilters = {};

        Object.entries(filters).forEach(([category, options]) => {
            if (options['All']) {
                transformedFilters[category] = { All: true };
            } else {
                const filteredOptions = Object.fromEntries(Object.entries(options).filter(([key, value]) => value));
                transformedFilters[category] = filteredOptions;
            }
        });

        return transformedFilters;
    };

    const applyFilters = () => {
        onApplyFilters({ filters: transformFilters(selectedFilters), dateRange });
    };

    const clearFilters = () => {
        setSelectedFilters({});
        setDateRange({ from: null, to: null });
        onClearFilters();
    };

    const filteredItems = filterOptions
        .map(({ category, options }, index) => {
            const filteredOptions = options.filter((option) => option.toLowerCase().includes(searchInput.toLowerCase()));
            if (filteredOptions.length > 0) {
                return {
                    key: `${index}`,
                    label: category,
                    children: (
                        <div className="filter-options">
                            {filteredOptions.map((option) => (
                                <label key={option}>
                                    <Checkbox checked={selectedFilters[category]?.[option] || false} onChange={() => handleFilterChange(category, option)} />
                                    {capitalize(option?.split('_').join(' '))}
                                </label>
                            ))}
                        </div>
                    ),
                };
            }

            return null;
        })
        .filter((item) => item !== null);

    filteredItems.push({
        key: 'date',
        label: 'Date',
        children: (
            <div className="flex items-center gap-[.75rem] justify-between w-full">
                <div className="p-[.25rem] rounded-[.25rem] bg-[#F1F5F9] flex items-center h-[2.75rem] w-full">
                    <DatePicker
                        // allowClear={{
                        //     clearIcon: (
                        //         <CloseCircleOutlined
                        //             onClick={() => {
                        //                 setDateRange((prev) => ({ ...prev, from: null }));
                        //                 dispatch(
                        //                     handleFilters({
                        //                         dateRange: {
                        //                             ...dateRange,
                        //                             from: null,
                        //                         },
                        //                     })
                        //                 );
                        //             }}
                        //         />
                        //     ),
                        // }}
                        maxDate={dayjs(new Date())}
                        minDate={dayjs(activeChama?.createdDate)}
                        placeholder="From"
                        suffixIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M6.51272 7.5H17.3984M5.76987 1.5V3.30022M17.9984 1.5V3.3M21.5984 6.3L21.5984 19.5001C21.5984 21.157 20.2553 22.5001 18.5984 22.5001H5.39844C3.74158 22.5001 2.39844 21.157 2.39844 19.5001V6.3C2.39844 4.64315 3.74158 3.3 5.39844 3.3H18.5984C20.2553 3.3 21.5984 4.64314 21.5984 6.3Z"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        }
                        value={dateRange.from ? dayjs(dateRange.from) : ''}
                        onChange={(e) => handleDateChange('from', e)}
                        style={{
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            fontSize: '16px',
                            color: '#5a6570',
                            boxShadow: 'none',
                        }}
                        popupClassName="custom-date-picker-popup"
                    />
                </div>

                <div className="p-[.25rem] rounded-[.25rem] bg-[#F1F5F9] flex items-center h-[2.75rem] w-full">
                    <DatePicker
                        placeholder="To"
                        minDate={dateRange.from ? dayjs(dateRange.from) : null}
                        maxDate={dayjs(new Date())}
                        suffixIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M6.51272 7.5H17.3984M5.76987 1.5V3.30022M17.9984 1.5V3.3M21.5984 6.3L21.5984 19.5001C21.5984 21.157 20.2553 22.5001 18.5984 22.5001H5.39844C3.74158 22.5001 2.39844 21.157 2.39844 19.5001V6.3C2.39844 4.64315 3.74158 3.3 5.39844 3.3H18.5984C20.2553 3.3 21.5984 4.64314 21.5984 6.3Z"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        }
                        value={dateRange.to ? dayjs(dateRange.to) : ''}
                        onChange={(e) => handleDateChange('to', e)}
                        style={{
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            fontSize: '16px',
                            color: '#5a6570',
                            boxShadow: 'none',
                        }}
                        popupClassName="custom-date-picker-popup"
                    />
                </div>
            </div>
        ),
    });

    // useEffect(() => {}, [selectedFilters, dateRange, filters]);

    return (
        <div className="filter-panel w-full flex flex-col gap-[1.5rem] md:w-[20.25rem]">
            <div className="filter-header">
                <div className="flex items-center gap-[.25rem]">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18M18 18L6 6" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                    </svg> */}
                    <h3 className="filter_header">{title}</h3>
                </div>
                <button className="filter_sub_header" onClick={clearFilters}>
                    Clear All
                </button>
            </div>
            <div className="w-full">
                <FilterSearch text={'Search'} handleSearch={handleSearch} searchInput={searchInput} />
            </div>
            <div className="filter-body">
                <Collapse
                    defaultActiveKey={['0', 'date']}
                    expandIcon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7.19922 14.4016L11.9992 9.60156L16.7992 14.4016" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    }
                    expandIconPosition="end"
                    items={filteredItems}
                />
            </div>
            <div className="filter-footer flex items-center justify-between gap-[1rem]">
                <button disabled={fiLoading} onClick={clearFilters}>
                    Clear all
                </button>
                <button disabled={fiLoading} onClick={applyFilters}>
                    {fiLoading ? <Spin /> : 'Apply filters'}
                </button>
            </div>
        </div>
    );
}
