import { Table } from 'antd';
import moment from 'moment';
import { applicationUrl, capitalize, capitalizeFirstLetter, formatMoney } from '../../../utils';
import useFetch from '../../../custom_hooks/useFetch';
import { useEffect, useMemo, useState } from 'react';
import TableLoading from '../../../components/TableLoading';
import info32 from '../../../assets/svg/Info32.svg';
import { useDispatch, useSelector } from 'react-redux';
import useDynamicFilter from '../../../custom_hooks/useDynamicFilter';
import { clearFilters, fetchWithdrawalFilter } from '../../../features/filters/filterSlice';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import ReimbursementInfoModal from '../modals/ReimbursementInfoModal';
import Search from '../../../components/Search';
import StatementFilter from '../../statements/StatementFilter';

const filterOptions = [
    {
        key: '0',
        category: 'Filter by type',
        options: ['All', 'BANK', 'MPESA'],
    },
];

export default function PendingRequestsTable() {
    const dispatch = useDispatch();

    const { isLoading, data } = useFetch(`${applicationUrl().url}/api/withdrawals/dynamic-withdraws?withDrawCategory=REIMBURSEMENT&withDrawStatus=PENDING_APPROVAL`);
    const moreInfo = useModalToggle();

    const { fiLoading, withdrawalFilters, filters } = useSelector((state) => state.filter);

    const [searchInput, setsearchInput] = useState('');
    const [saveObj, setsaveObj] = useState({});
    const [filterView, setfilterView] = useState(false);

    const filterFns = useMemo(() => {
        const search = searchInput.toUpperCase();
        return [
            (item) => {
                if (!search) return item;
                const { withDrawAmount, withDrawCreatedDate, withDrawStatus, withDrawMpesaNumber, withDrawParticular } = item || {};
                return (
                    String(withDrawAmount).includes(search) ||
                    moment(withDrawCreatedDate).format('Do MMM YYYY').toUpperCase().includes(search?.toUpperCase()) ||
                    String(withDrawStatus).toUpperCase().includes(search?.toUpperCase()) ||
                    capitalize(withDrawMpesaNumber?.split('_').join(' ')).toUpperCase().includes(search?.toUpperCase()) ||
                    capitalize(withDrawParticular?.split('_').join(' ')).toUpperCase().includes(search?.toUpperCase())
                );
            },
        ];
    }, [searchInput]);

    const filteredData = useDynamicFilter(data, filterFns);

    const handleSearch = (val) => setsearchInput(val);

    const handleApplyFilters = async (filters) => {
        const preparedFilters = {
            ...filters,
            filters: {
                ...filters?.filters,
                'Filter by activity': { REIMBURSEMENT: true },
                'Filter by type': filters?.filters['Filter by type'] || {},
                'Filter by status': {PENDING_APPROVAL: true},
            },
        };
        delete preparedFilters.filters?.dateRange;
        await dispatch(fetchWithdrawalFilter(preparedFilters));
        await setfilterView(true);
    };

    const handleClearFilters = () => {
        dispatch(clearFilters());
        setfilterView(false);
    };

    function handleInfo(item) {
        setsaveObj(item);
        moreInfo.handleOpen();
    }

    async function handleClose() {
        await setsaveObj({});
        await moreInfo.handleCancel();
    }

    useEffect(() => {
        dispatch(clearFilters());
    }, [dispatch]);

    useEffect(() => {}, [data, withdrawalFilters, filters]);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'withDrawCreatedDate',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        },
        {
            title: 'Amount',
            dataIndex: 'withDrawAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Source account',
            dataIndex: 'withDrawCwCollection',
        },
        {
            title: 'Recipient no.',
            dataIndex: 'withDrawMpesaNumber',
        },
        {
            title: 'Reason for request',
            dataIndex: 'withDrawParticular',
            render: (item) => <span>{capitalizeFirstLetter(item)}</span>,
        },
        {
            title: 'Additional info',
            render: (item) => (
                <button onClick={() => handleInfo(item)} type="button">
                    <img src={info32} alt="info" />
                </button>
            ),
        },
    ];

    const hasFiltersApplied = Object.keys(filters || {}).length > 0;
    const noRecords = filterView && hasFiltersApplied && (withdrawalFilters?.length === 0);    

    if (isLoading || fiLoading) return <TableLoading />;
    
        return (
            <>
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-[1rem] w-full">
                        <div className="w-[13.625rem] hidden lg:flex">
                            <Search handleSearch={handleSearch} searchInput={searchInput} readOnly text="Search" />
                        </div>
    
                        <div className="w-[12.5rem] hidden lg:flex">
                            <StatementFilter disabled filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} handleClearFilters={handleClearFilters} />
                        </div>
                    </div>
                </div>
    
                {isLoading || fiLoading ? (
                    <TableLoading />
                ) : noRecords ? (
                    <Table rowKey="withDrawId" className="mt-[1.5rem] !w-full" scroll={{ x: 1000 }} columns={columns} dataSource={[]} />
                ) : (
                    <Table
                        rowKey="withDrawId"
                        className="mt-[1.5rem] !w-full"
                        pagination={{
                            defaultPageSize: 7,
                            hideOnSinglePage: true,
                            pageSizeOptions: [10, 20, 50, 100],
                        }}
                        scroll={{ x: 1200 }}
                        columns={columns}
                        dataSource={withdrawalFilters?.length ? withdrawalFilters : filteredData}
                    />
                )}
    
                <ReimbursementInfoModal open={moreInfo.open} handleOkay={handleClose} selectedItem={saveObj} reimbursement={true} />
            </>
        );
}
