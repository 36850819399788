import { Table } from 'antd';
import moment from 'moment';
import { applicationUrl, capitalize, formatMoney } from '../../../utils';
import TableLoading from '../../../components/TableLoading';
import useFetch from '../../../custom_hooks/useFetch';
import { debitNoteTypeColor } from '../../../data';
import Search from '../../../components/Search';
import StatementFilter from '../../statements/StatementFilter';
import { useEffect, useMemo, useState } from 'react';
import useDynamicFilter from '../../../custom_hooks/useDynamicFilter';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilters, fetchTransactionFilter } from '../../../features/filters/filterSlice';

const columns = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        sorter: (a, b) => a - b,
    },
    {
        title: 'Debit (KES)',
        render: (item) => {
            const { trxType, trxAmount } = item;
            if (trxType === 'DEBIT') {
                return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
            }
            return <span>-</span>;
        },
    },
    {
        title: 'Credit (KES)',
        render: (item) => {
            const { trxType, trxAmount } = item;
            if (trxType === 'CREDIT') {
                return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
            }
            return <span>-</span>;
        },
    },
    {
        title: 'Ref no.',
        dataIndex: 'transId',
        render: (item) => <span>{item ? item : ' - '}</span>,
    },
    {
        title: 'Activity',
        dataIndex: 'trxSource',
        render: (item) => {
            const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6';

            return (
                <div
                    style={{
                        background: roleColor,
                    }}
                    className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                        <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                    </svg>
                    <span>{capitalize(item?.split('_').join(' '))}</span>
                </div>
            );
        },
    },
];

const filterOptions = [
    {
        key: '0',
        category: 'Filter by type',
        options: ['All', 'DEBIT', 'CREDIT'],
    },
    {
        key: '1',
        category: 'Filter by activity',
        options: ['All', 'OUTSTANDING_BALANCE', 'ARREARS', 'CONTRIBUTION', 'WITHDRAWAL', 'TRANSFER', 'Penalty', 'Other'],
    },
];

export default function ActivityRecentTransactionTable() {
    const dispatch = useDispatch();

    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/transactions/recent`);
    const { fiLoading, transactionFilters, filters } = useSelector((state) => state.filter);

    const [searchInput, setsearchInput] = useState('');
    const [filterView, setfilterView] = useState(false);

    const filterFns = useMemo(() => {
        const search = searchInput.toUpperCase();
        return [
            (item) => {
                if (!search) return item;
                const { trxAmount, createdAt, transId, trxSource } = item || {};
                return (
                    String(trxAmount).includes(search) ||
                    moment(createdAt).format('Do MMM YYYY').toUpperCase().includes(search) ||
                    String(transId).toUpperCase().includes(search) ||
                    capitalize(trxSource?.split('_').join(' ')).toUpperCase().includes(search)
                );
            },
        ];
    }, [searchInput]);

    const filteredData = useDynamicFilter(data?.content, filterFns);

    const handleSearch = (val) => setsearchInput(val);

    const handleApplyFilters = async (filters) => {
        const preparedFilters = {
            ...filters,
            filters: {
                ...filters?.filters,
                'Filter by activity': filters?.filters['Filter by activity'] || {},
                'Filter by type': filters?.filters['Filter by type'] || {},
            },
            pageCount: 7,
        };
        delete preparedFilters.filters?.dateRange;
        await dispatch(fetchTransactionFilter(preparedFilters));
        await setfilterView(true);
    };

    const handleClearFilters = () => {
        dispatch(clearFilters());
        setfilterView(false);
    }

    const hasFiltersApplied = Object.keys(filters || {}).length > 0;
    const noRecords = filterView && hasFiltersApplied && (!transactionFilters?.content || transactionFilters?.content?.length === 0);

    useEffect(() => {
        dispatch(clearFilters());
    }, []);

    useEffect(() => {}, [transactionFilters, filters, data]);

    if (isLoading || fiLoading) return <TableLoading />;

    return (
        <>
            <div className="flex items-center gap-[1rem] w-full">
                <div className="w-[13.625rem]">
                    <Search handleSearch={handleSearch} searchInput={searchInput} readOnly text="Search" />
                </div>
                <div className="w-[12.5rem]">
                    <StatementFilter disabled filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} handleClearFilters={handleClearFilters} />
                </div>
            </div>

            {isLoading || fiLoading ? (
                <TableLoading />
            ) : noRecords ? (
                <Table rowKey="trxId" className="mt-[1.5rem] !w-full" scroll={{ x: 1000 }} columns={columns} dataSource={[]} />
            ) : (
                <Table
                    rowKey="trxId"
                    className="mt-[1.5rem] !w-full"
                    pagination={{
                        defaultPageSize: 7,
                        hideOnSinglePage: true,
                        pageSizeOptions: [10, 20, 50, 100],
                    }}
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={transactionFilters?.content?.length ? transactionFilters.content : filteredData}
                />
            )}
        </>
    );
}
