import React, { useEffect, useRef, useState } from 'react';
import { Form, InputNumber, Modal, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, capitalize, capitalizeFirstLetter, customToast, formatMoney } from '../../../utils';
import { fetchSettlementAccounts } from '../../../features/fetch/fetchSlice';
import useFetch from '../../../custom_hooks/useFetch';
import TextArea from 'antd/es/input/TextArea';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import { deleteEntry, save } from '../../../features/save/saveSlice';
import { fetchSettlements, fetchWithdrawalRates, handleResetrates } from '../../../features/money/moneySlice';
import AllPurposeOtpModal from '../../../components/AllPurposeOtpModal';
import RequestSentModal from '../../../components/RequestSentModal';

export default function SettlementModal({ open, handleCancel, withdrawalType }) {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const { data } = useFetch(`${applicationUrl().url}/api/v1/wallet`);

    const { saving } = useSelector((state) => state.save);
    const { settlementAccounts, loading } = useSelector((state) => state.fetch);
    const { withdrawalRates, moneying } = useSelector((state) => state.money);

    const veriyModal = useModalToggle();
    const success = useModalToggle();

    const { activeChama } = useSelector((state) => state.obj);

    const [resData, setresData] = useState({});
    const [amount, setamount] = useState(0);

    async function handleWithdrawalRates(val) {
        if (typeof val !== 'number') {
            val = 0;
        }
        let tarrifObj = {
            amount: val,
            type: withdrawalType === 'Bank' ? 'BANK' : 'MPESA',
        };
        await dispatch(fetchWithdrawalRates(tarrifObj));
    }

    async function handleCancelRequest() {
        const deleteObj = {
            url: applicationUrl().url,
            saveUrl: `/api/v2/ndovupay/${resData?.withDrawId}`,
        };
        await dispatch(deleteEntry(deleteObj));
        await setresData({});
        await veriyModal.handleCancel();
        await dispatch(fetchSettlements());
        await handleClose();
    }

    async function handleOkay() {
        await dispatch(fetchSettlements());
        await success.handleCancel();
    }

    async function handleNext(code) {
        const saveObj = {
            withDrawLogId: resData?.withDrawId,
            withDrawGpmNumber: resData?.withDrawGpmNumber,
            withDrawCode: code,
            url: applicationUrl().url,
            saveUrl: '/api/v2/ndovupay/finalize-withdraw',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await handleClose();
            await veriyModal.handleCancel();
            await success.handleOpen();
            await setresData({});
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'We cannot process your request at the momemnt. Please try again later',
                bdColor: 'error',
                id: 88337,
            });
        }
    }

    const onFinish = async (data) => {
        data.withDrawType = withdrawalType === 'Bank' ? 'BANK' : 'MPESA';
        data.withDrawCategory = 'SETTLEMENT';
        data.withDrawAmount = withdrawalRates?.total;
        data.withdrawalCommission = withdrawalRates?.total - amount;

        if (!data.withDrawAmount || data.withDrawAmount < 50) {
            return customToast({
                content: 'Minimum withdrawal amount is KES 50',
                bdColor: 'error',
                id: 8796444444,
            });
        }

        const saveObj = {
            ...data,
            url: applicationUrl().url,
            saveUrl: '/api/v2/ndovupay/initiate-withdraw',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await setresData(res?.payload?.data?.result);
            await handleCancel();
            await veriyModal.handleOpen();
            await dispatch(handleResetrates());
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'We cannot process your request at the momemnt. Please try again later',
                bdColor: 'error',
                id: 7382,
            });
        }
    };

    async function handleFetch() {
        await dispatch(fetchSettlementAccounts(activeChama?.custId));
    }

    async function handleClose() {
        await form.resetFields();
        await handleCancel();
        await dispatch(handleResetrates());
    }

    useEffect(() => {
        handleWithdrawalRates(amount);
    }, [amount]);

    useEffect(() => {}, [withdrawalRates]);

    return (
        <>
            <Modal
                afterOpenChange={handleFetch}
                centered
                className="custom-modal"
                title={`${capitalize(withdrawalType)} settlement`}
                open={open}
                onCancel={handleClose}
                footer={false}
            >
                <div className="flex justify-between items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]">
                    <span className="heading_4 !text-white">{`${capitalize(withdrawalType)} settlement`}</span>
                    <button onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </div>

                <div className="px-[2.94rem] pt-[2rem]">
                    <div className="w-full flex flex-col justify-center items-center">
                        <Form
                            layout="vertical"
                            ref={formRef}
                            name="otpModal"
                            onFinish={onFinish}
                            style={{
                                maxWidth: '100%',
                                width: '100%',
                            }}
                            form={form}
                        >
                            <Form.Item
                                label="Source account"
                                name="withDrawCwId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Field is required',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option?.stringLabel?.toLowerCase()?.includes(input?.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA?.stringLabel?.toLowerCase()?.localeCompare(optionB?.stringLabel?.toLowerCase())}
                                    suffixIcon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <path
                                                d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                                                fill="#212121"
                                            />
                                        </svg>
                                    }
                                    options={data
                                        ?.filter((x) => x?.customerWallet?.cwWalletType !== 'MAIN')
                                        ?.map((item) => {
                                            const labelText = `${capitalize(item?.customerWallet?.cwName)} | ${formatMoney(item?.customerWallet?.cwWalAmount, 'KES')}`;
                                            return {
                                                stringLabel: labelText,
                                                label: <span>{labelText}</span>,
                                                value: item?.customerWallet?.cwId,
                                            };
                                        })}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Recipient account"
                                name="withDrawSettlementAccount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Field is required',
                                    },
                                ]}
                            >
                                <Select
                                    loading={loading}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option?.stringLabel?.toLowerCase()?.includes(input?.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA?.stringLabel?.toLowerCase()?.localeCompare(optionB?.stringLabel?.toLowerCase())}
                                    suffixIcon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <path
                                                d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                                                fill="#212121"
                                            />
                                        </svg>
                                    }
                                    options={settlementAccounts
                                        ?.filter((acc) => acc?.staType === (withdrawalType === 'Bank' ? 'BANK' : 'MPESA'))
                                        ?.map((item) => {
                                            const labelText = `${capitalizeFirstLetter(item?.staName)} | ${item?.staAccountNumber} ${
                                                withdrawalType === 'Bank' ? ' | ' + capitalizeFirstLetter(item?.staBankName) : ''
                                            }`;
                                            return {
                                                stringLabel: labelText,
                                                label: <span>{labelText}</span>,
                                                value: item?.staId,
                                            };
                                        })}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Amount"
                                name="withDrawAmount"
                                extra={
                                    moneying ? (
                                        <Spin
                                            style={{
                                                paddingTop: '.2rem',
                                            }}
                                            className="blu_spin"
                                        />
                                    ) : (
                                        <span className="text-[green]">Total {`${formatMoney(withdrawalRates?.total, 'KES')} inclusive of convenience fee`}</span>
                                    )
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Minimum amount is KES 50',
                                    },
                                ]}
                            >
                                <InputNumber onChange={(val) => setamount(val)} value={amount} className="input" />
                            </Form.Item>

                            <Form.Item
                                label="Reason"
                                name="withDrawParticular"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Field is required',
                                    },
                                ]}
                            >
                                <TextArea rows={5} showCount maxLength={255} className="text_area" />
                            </Form.Item>

                            <div className="flex items-center justify-center mt-[5rem] w-full">
                                <div className="w-fit flex flex-col gap-[.5rem]">
                                    <button disabled={saving || moneying} className="cstm-btn-2" type="submit">
                                        {saving ? <Spin /> : 'Submit for approval'}
                                    </button>

                                    <button disabled={saving} onClick={handleClose} className="cstm-btn" type="button">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>

            <AllPurposeOtpModal open={veriyModal.open} handleNext={handleNext} handleCancel={handleCancelRequest} />
            <RequestSentModal
                open={success.open}
                handleOkay={handleOkay}
                header={'Account settlement request sent'}
                text={'Settlement request sent to signatories. You will be notified once the transaction has been approved'}
            />
        </>
    );
}
