import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/authSlice';
import fetchReducer from '../features/fetch/fetchSlice';
import saveReducer from '../features/save/saveSlice';
import setupReducer from '../features/setup/setupSlice';
import objReducer from '../features/obj/objSlice';
import optionReducer from '../features/options/optionSLice';
import moneyReducer from '../features/money/moneySlice';
import approvalReducer from '../features/approvals/approvalSlice';
import filterReducer from '../features/filters/filterSlice';

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    auth: authReducer,
    fetch: fetchReducer,
    save: saveReducer,
    setup: setupReducer,
    obj: objReducer,
    option: optionReducer,
    money: moneyReducer,
    approval: approvalReducer,
    filter: filterReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);
