import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Modal, Radio, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, customToast, formatBankPhoneNumber } from '../../../utils';
import { fetchBanks, veriBankAccount } from '../../../features/options/optionSLice';
import PhoneInput from 'react-phone-input-2';
import FileUploader from '../../../components/FileUploader';
import { save } from '../../../features/save/saveSlice';
import RequestSentModal from '../../../components/RequestSentModal';
import useModalToggle from '../../../custom_hooks/useModalToggle';

export default function AddSettlementAccountModal({ open, handleCancel, handleFetch }) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formRef = useRef(null);

    const success = useModalToggle();

    const { saving } = useSelector((state) => state.save);
    const { activeChama } = useSelector((state) => state.obj);
    const { banks, optLoading } = useSelector((state) => state.option);

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const [accType, setaccType] = useState('Bank');
    const [verify, setverify] = useState(false);
    const [staProof, setstaProof] = useState('');
    const [verifyObj, setverifyObj] = useState({});
    const [phone, setphone] = useState('');
    const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
    const [accId, setaccId] = useState();

    const cancatenatedString = verificationCode?.join('');

    const handleSuccess = (response) => {
        setstaProof(response?.data?.result);
    };

    async function handleFetchBanks() {
        await dispatch(fetchBanks());
    }

    const onFinish = async (values) => {
        values.url = applicationUrl().url;
        values.saveUrl = '/api/v1/debt-note/add';
    };

    async function handleVerifyBank() {
        try {
            await form.validateFields(['staBankCode', 'staAccountNumber', 'staPhoneNumber']);
            let data = form.getFieldsValue();
            let bankObj = banks?.find((item) => item?.bnkNo === data?.staBankCode);
            data.staBankName = bankObj?.bnkName;
            data.staProof = staProof;
            data.staCustId = activeChama?.custId;
            data.staPhoneNumber = formatBankPhoneNumber(data?.staPhoneNumber);
            data.staType = 'BANK';
            data.url = applicationUrl().url;
            data.saveUrl = '/api/v1/settlement';

            const otherObj = {
                accountNo: data.staAccountNumber,
                bankCode: data.staBankCode,
            };

            const res = await dispatch(veriBankAccount(otherObj));

            if (res?.payload?.status === 'SUCCESS') {
                setverifyObj({
                    ...data,
                    staName: res?.payload?.benname,
                });

                await setverify(true);
            } else {
                customToast({
                    content: 'Could not verify your bank account.',
                    bdColor: 'error',
                    id: 927722222,
                });
            }
        } catch {}
    }

    async function handleSave() {
        try {
            const res = await dispatch(save(verifyObj));

            if (res?.payload?.success) {
                await handleClose();
                await success.handleOpen();
            } else {
                await setverify(false);
                customToast({
                    content: res?.payload?.messages?.message ?? 'An error occured',
                    bdColor: 'error',
                });
            }
        } catch {}
    }

    async function handleSaveMpesa() {
        try {
            await form.validateFields(['staAccountNumber', 'staName']);
            let data = form.getFieldsValue();
            data.staCustId = activeChama?.custId;
            data.staType = 'MPESA';
            data.url = applicationUrl().url;
            data.saveUrl = '/api/v1/settlement';

            const res = await dispatch(save(data));

            if (res?.payload?.success) {
                await setaccId(res?.payload?.data?.result?.staId);
                await setverify(true);
            } else {
                customToast({
                    content: res?.payload?.messages?.message ?? 'An error occured',
                    bdColor: 'error',
                });
            }
        } catch {}
    }

    async function handleVerifyCode() {
        try {
            let data = {
                secretId: accId,
                secret: cancatenatedString,
                url: applicationUrl().url,
                saveUrl: '/api/v1/settlement/verifyOTP',
            };

            const res = await dispatch(save(data));

            if (res?.payload?.success) {
                await handleClose();
                await success.handleOpen();
            } else {
                customToast({
                    content: res?.payload?.messages?.message ?? 'An error occured',
                    bdColor: 'error',
                });
                setVerificationCode(['', '', '', '']);
                inputRefs.forEach((item) => item?.current?.value === null);
            }
        } catch {}
    }

    const handleInputChange = (e, index) => {
        const { value } = e.target;

        if (value === '') {
            if (index > 0) {
                inputRefs[index - 1].current.focus();
            }
        } else if (index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }

        const updatedVerificationCode = [...verificationCode];
        updatedVerificationCode[index] = value;
        setVerificationCode(updatedVerificationCode);
    };

    useEffect(() => {
        handleFetchBanks();
    }, []);

    async function handleClose() {
        await setaccType('Bank');
        await setstaProof('');
        await setverify(false);
        await setverifyObj({});
        await setphone('');
        await setaccId();
        await setVerificationCode(['', '', '', '']);
        await inputRefs.forEach((item) => item?.current?.value === null);
        await handleCancel();
        await form.resetFields();
    }

    async function handleOkay() {
        await success.handleCancel();
        await handleFetch();
    }

    return (
        <>
            <Modal forceRender centered className="custom-modal" title={verify ? 'Verification' : 'Add settlement account'} open={open} onCancel={handleClose} footer={false}>
                <div className="flex justify-between items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]">
                    <span className="heading_4 !text-white">{verify ? 'Verification' : 'Add settlement account'}</span>
                    <button onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </div>

                <div className="px-[2.94rem] pt-[1.63rem]">
                    <div className="w-full flex flex-col items-start">
                        {verify ? null : (
                            <>
                                <Radio.Group value={accType} onChange={(e) => setaccType(e.target.value)}>
                                    <Radio value={'Bank'}>Bank</Radio>
                                    <Radio className="ml-[3.56rem]" value={'MPESA'}>
                                        M-PESA
                                    </Radio>
                                </Radio.Group>

                                <span className="label_2 mt-[1.94rem]">Fill in the following details</span>
                            </>
                        )}

                        <Form
                            layout="vertical"
                            ref={formRef}
                            name="addSettlementModal"
                            onFinish={onFinish}
                            style={{
                                maxWidth: '100%',
                                width: '100%',
                            }}
                            form={form}
                        >
                            {accType === 'Bank' && !verify ? (
                                <>
                                    <Form.Item
                                        className="mt-[1.63rem]"
                                        label="Choose bank"
                                        name="staBankCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Field is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
                                            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                            suffixIcon={
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path
                                                        d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                                                        fill="#212121"
                                                    />
                                                </svg>
                                            }
                                            options={banks?.map((item) => {
                                                return {
                                                    label: item?.bnkName,
                                                    value: item?.bnkNo,
                                                };
                                            })}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Phone number"
                                        name="staPhoneNumber"
                                        extra="e.g 254 723456789"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Field is required',
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (value && value.length === 12) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Invalid format. Use the correct format'));
                                                },
                                            },
                                        ]}
                                    >
                                        <PhoneInput enableSearch country="ke" countryCodeEditable={false} className="input" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Account number"
                                        name="staAccountNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Field is required',
                                            },
                                        ]}
                                    >
                                        <Input className="input" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Upload recent statement (.pdf)"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Field is required',
                                            },
                                        ]}
                                    >
                                        <FileUploader fileName={staProof?.split('_').pop()} name="staProof" onSuccess={handleSuccess} />
                                    </Form.Item>

                                    <div className="py-[2.44rem] w-full flex justify-center items-center">
                                        <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                            <button disabled={optLoading} onClick={handleVerifyBank} type="button" className="cstm-btn-2">
                                                {optLoading ? <Spin /> : 'Verify'}
                                            </button>

                                            <button disabled={optLoading} onClick={handleClose} type="button" className="cstm-btn">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : verify && accType === 'Bank' ? (
                                <>
                                    <div className="flex flex-col justify-center items-center gap-[1.32rem]">
                                        <span className="heading_4">Verify account</span>
                                        <div
                                            style={{
                                                background: '#D3D3D9',
                                            }}
                                            className="flex gap-[1.5rem] justify-between items-center w-full lg:w-[35rem] rounded-[0.5rem] min-h-[4.625rem] h-auto px-[2rem] py-[.5rem]"
                                        >
                                            <div className="flex flex-col gap-[.75rem] items-start">
                                                <span className="role_header">
                                                    {verifyObj?.staAccountNumber} | {verifyObj?.staBankName} BANK
                                                </span>
                                                <span className="role_header">{verifyObj?.staName}</span>
                                                {verifyObj?.staProof ? <span className="role_header underline">{verifyObj?.staProof?.split('_').pop()}</span> : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="py-[2.44rem] w-full flex justify-center items-center">
                                        <div className="w-fit flex flex-col gap-[.5rem]">
                                            <button disabled={saving} onClick={handleSave} type="button" className="cstm-btn-2">
                                                {saving ? <Spin /> : 'Submit for approval'}
                                            </button>

                                            <button
                                                disabled={saving}
                                                onClick={() => {
                                                    setverify(false);
                                                    setverifyObj({});
                                                }}
                                                type="button"
                                                className="cstm-btn"
                                            >
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : accType !== 'Bank' && !verify ? (
                                <>
                                    <Form.Item
                                        label="M-PESA Phone number"
                                        name="staAccountNumber"
                                        className="mt-[3rem]"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Field is required',
                                            },
                                        ]}
                                    >
                                        <Input
                                            value={phone}
                                            onChange={(e) => setphone(e.target.value)}
                                            onKeyDown={(e) => {
                                                e.key === 'Enter' && e.preventDefault();
                                            }}
                                            className="input"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        className="table-form-item"
                                        label="Account name"
                                        name="staName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Field is required',
                                            },
                                        ]}
                                    >
                                        <Input className="input" />
                                    </Form.Item>

                                    <div className="py-[2.44rem] w-full flex justify-center items-center">
                                        <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                            <button disabled={saving} onClick={handleSaveMpesa} type="button" className="cstm-btn-2">
                                                {saving ? <Spin /> : 'Next'}
                                            </button>

                                            <button disabled={saving} onClick={handleClose} type="button" className="cstm-btn">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : accType !== 'Bank' && verify ? (
                                <>
                                    <div className="flex flex-col justify-center items-center mt-[3.38rem] gap-[1.32rem]">
                                        <span className="paragraph_3">Enter OTP sent to {phone}</span>
                                        <div className="flex items-center gap-[1.25rem]">
                                            {inputRefs?.map((ref, index) => (
                                                <div key={index}>
                                                    <input
                                                        ref={ref}
                                                        className="otp-input"
                                                        type="text"
                                                        maxLength="1"
                                                        value={verificationCode[index]}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        onKeyDown={(e) => {
                                                            e.key === 'Enter' && e.preventDefault();
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="py-[2.44rem] w-full flex justify-center items-center">
                                        <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                                            <button disabled={saving} onClick={handleVerifyCode} type="button" className="cstm-btn-2">
                                                {saving ? <Spin /> : 'Verify'}
                                            </button>

                                            <button
                                                disabled={saving}
                                                onClick={() => {
                                                    setverify(false);
                                                    setverifyObj({});
                                                    setaccId();
                                                    setVerificationCode(['', '', '', '']);
                                                    inputRefs.forEach((item) => item?.current?.value === null);
                                                }}
                                                type="button"
                                                className="cstm-btn"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </Form>
                    </div>
                </div>
            </Modal>

            <RequestSentModal open={success.open} handleOkay={handleOkay} header="Success" text="Settlement account sent for approval" />
        </>
    );
}
