import { Dropdown, Table } from 'antd';
import moment from 'moment';
import { applicationUrl, formatMoney, getRandomColor } from '../../../utils';
import useFetch from '../../../custom_hooks/useFetch';
import TableLoading from '../../../components/TableLoading';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ExportToPDFModal from '../../../components/ExportToPdfModal';
import useModalToggle from '../../../custom_hooks/useModalToggle';

export default function MembersArrearsTable() {
    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/schedule/all`);

    const { open, handleCancel, handleOpen } = useModalToggle();

    const { user } = useSelector((state) => state.auth);

    const [selectedObj, setselectedObj] = useState({});
	const [memberNo, setmemberNo] = useState('')

    function handlePDFView(item) {
        setmemberNo(selectedObj?.schGrpMemberNo)
        handleOpen();
    }

    function handleCLose() {
        setselectedObj({});
		setmemberNo('')
        handleCancel();
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'schCreatedDate',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        },
        {
            title: 'Member',
            dataIndex: 'schGrpMemberName',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img
                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item)}&background=${getRandomColor()}&color=fff`}
                            className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                            alt="avatar"
                        />
                        <span>{item}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Amount (KES)',
            dataIndex: 'totalSchContAmount',
            render: (item) => <button type="button">{formatMoney(item, 'KES')}</button>,
        },
        {
            title: 'Action',
            render: (_, record) => {
                return (
                    <Dropdown
                        overlayStyle={{
                            width: '11.8125rem',
                            margin: '.5rem',
                            boxShadow: ' 0px 1px 12px 0px rgba(25, 27, 35, 0.10)',
                        }}
                        overlayClassName="avatar_dropdown"
                        arrow
                        menu={{
                            items: items,
                        }}
                        onOpenChange={(open) => {
                            if (open) {
                                setselectedObj(record);
                            } else {
                                setselectedObj({});
                            }
                        }}
                        trigger={['click']}
                        placement="bottom"
                    >
                        <button type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path
                                    d="M16 17.5C16.8284 17.5 17.5 16.8284 17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16C14.5 16.8284 15.1716 17.5 16 17.5Z"
                                    fill="#343330"
                                />
                                <path d="M24.5 17.5C25.3284 17.5 26 16.8284 26 16C26 15.1716 25.3284 14.5 24.5 14.5C23.6716 14.5 23 15.1716 23 16C23 16.8284 23.6716 17.5 24.5 17.5Z" fill="#343330" />
                                <path d="M7.5 17.5C8.32843 17.5 9 16.8284 9 16C9 15.1716 8.32843 14.5 7.5 14.5C6.67157 14.5 6 15.1716 6 16C6 16.8284 6.67157 17.5 7.5 17.5Z" fill="#343330" />
                            </svg>
                        </button>
                    </Dropdown>
                );
            },
        },
    ];

    const items = [
        {
            key: '1',
            label: (
                <div onClick={() => handlePDFView(selectedObj)} className="pointer w-full">
                    Export to pdf
                </div>
            ),
        },
    ];

    if (isLoading) return <TableLoading />;

    return (
        <>
            <Table
                rowKey="schGrpMemberNo"
                className="mt-[1.5rem] !w-full"
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 700,
                }}
            />

            <ExportToPDFModal open={open} handleCancel={handleCLose} P_STATEMENT={'ACCOUNT_STATEMENT_DEBIT_NOTES_MEMBER'} rptId={4} P_MEMBER_NO={memberNo} P_USER={user?.userName} />
        </>
    );
}
