import { Dropdown, Popconfirm, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, capitalize, customToast } from '../../../../utils';
import { deleteEntry, save } from '../../../../features/save/saveSlice';
import dayjs from 'dayjs';
import TableLoading from '../../../../components/TableLoading';
import { fetchChamaContributions } from '../../../../features/fetch/fetchSlice';
import CreateGroupContributionTypeModal from '../../../group/modals/CreateGroupContributionTypeModal';
import useModalToggle from '../../../../custom_hooks/useModalToggle';

export default function GroupDetailsSubStepTwo({ handleActiveStep, form, handleNext }) {
    const dispatch = useDispatch();
    const { open, handleCancel, handleOpen } = useModalToggle();

    const { newChamaData } = useSelector((state) => state.obj);
    const { saving } = useSelector((state) => state.save);
    const { chamaContributions, loading } = useSelector((state) => state.fetch);

    const [dataSource, setDataSource] = useState([]);
    const [activeContributionType, setactiveContributionType] = useState({});
    const [selectedObj, setselectedObj] = useState({});

    function handleClose() {
        setactiveContributionType({});
        handleCancel();
    }

    async function handleEdit() {
        await setactiveContributionType(selectedObj);
        await handleOpen();
    }

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //     getCheckboxProps: (record) => ({
    //         disabled: record?.rdnType === 'SAVING',
    //     }),
    // };

    const handleDelete = async () => {
        if (activeContributionType?.rdnType === 'SAVING') {
            return customToast({
                content: 'Default contribution cannot be removed.',
                bdColor: '',
                id: 3893000,
            });
        }
        const values = {};
        values.url = applicationUrl().url;
        values.saveUrl = `/api/v1/debit-setup/${activeContributionType?.rdnId}`;

        const res = await dispatch(deleteEntry(values));

        if (res?.payload?.success) {
            await handleFetch();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'An error occured',
                bdColor: 'error',
                id: 736328,
            });
        }
    };

    async function handleFetch() {
        await dispatch(fetchChamaContributions(newChamaData?.custId));
    }

    async function handleSubmitStep() {
        try {
            for (const item of dataSource) {
                if (item?.rdnAmount <= 0) {
                    return customToast({
                        content: 'Contribution amount cannot be KES 0',
                        bdColor: '',
                        id: 9377710,
                    });
                }
            }

            await handleActiveStep(1);
            await handleNext();
        } catch (error) {
            console.error('Error in handleSubmitStep:', error);
        }
    }

    const optionItems = [
        {
            key: '1',
            label: (
                <div onClick={handleEdit} className="pointer w-full">
                    Edit
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <Popconfirm
                    okText="Confirm"
                    cancelText="Cancel"
                    cancelButtonProps={{
                        style: {
                            background: '#D3D3D9',
                            color: '#212143',
                            fontWeight: 500,
                            fontSize: '.875rem',
                            borderRadius: '0.375rem',
                            padding: '.5rem',
                            width: 'fit-content',
                            height: '2.0625rem',
                            marginRight: 10,
                        },
                    }}
                    okButtonProps={{
                        style: {
                            background: '#212121',
                            color: '#fff',
                            fontWeight: 500,
                            fontSize: '.875rem',
                            borderRadius: '0.375rem',
                            padding: '.5rem',
                            width: 'fit-content',
                            height: '2.0625rem',
                        },
                    }}
                    title="Are you sure to you want to remove this member?"
                    onConfirm={handleDelete}
                >
                    <div className="pointer w-full">Remove entry</div>
                </Popconfirm>
            ),
        },
    ];

    const defaultColumns = [
        {
            title: 'Type of contribution',
            dataIndex: 'rdnType',
            render: (item) => <span>{capitalize(item)}</span>,
        },
        {
            title: 'Amount (KES)',
            dataIndex: 'rdnAmount',
        },
        {
            title: 'Frequency',
            dataIndex: 'rdnFrequencyDescription',
        },
        {
            title: 'Contribution day',
            dataIndex: 'rdnContributionDay',
        },
        {
            title: 'Contribution start date',
            dataIndex: 'rdnStartDate',
            render: (item) => <span>{dayjs(item).format('Do MMM YYYY')}</span>,
        },
        {
            title: 'Action',
            render: (item) => (
                <Dropdown
                    overlayStyle={{
                        width: '11.8125rem',
                        margin: '.5rem',
                        boxShadow: ' 0px 1px 12px 0px rgba(25, 27, 35, 0.10)',
                    }}
                    overlayClassName="avatar_dropdown"
                    arrow
                    menu={{
                        items: optionItems,
                    }}
                    onOpenChange={(open) => {
                        if (open) {
                            setselectedObj(item);
                        } else {
                            setselectedObj({});
                        }
                    }}
                    trigger={['click']}
                    placement="bottom"
                >
                    <button type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path
                                d="M16 17.5C16.8284 17.5 17.5 16.8284 17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16C14.5 16.8284 15.1716 17.5 16 17.5Z"
                                fill="#343330"
                            />
                            <path
                                d="M24.5 17.5C25.3284 17.5 26 16.8284 26 16C26 15.1716 25.3284 14.5 24.5 14.5C23.6716 14.5 23 15.1716 23 16C23 16.8284 23.6716 17.5 24.5 17.5Z"
                                fill="#343330"
                            />
                            <path
                                d="M7.5 17.5C8.32843 17.5 9 16.8284 9 16C9 15.1716 8.32843 14.5 7.5 14.5C6.67157 14.5 6 15.1716 6 16C6 16.8284 6.67157 17.5 7.5 17.5Z"
                                fill="#343330"
                            />
                        </svg>
                    </button>
                </Dropdown>
            ),
        },
    ];

    useEffect(() => {
        if (dataSource?.length === 1) {
            let firstObj = dataSource[0];
            if (firstObj?.rdnAmount === 0) {
                setselectedObj(firstObj);      
            }else{
                setselectedObj({})
            }
        }
    }, [dataSource]);

    useEffect(() => {
        if (dataSource?.length === 1) {
            if (selectedObj?.rdnAmount === 0 && selectedObj?.rdnId?.length) {
                handleEdit();
            }
        }
    }, [selectedObj]);

    useEffect(() => {
        setDataSource(chamaContributions);
    }, [chamaContributions]);

    useEffect(() => {
        handleFetch();
    }, []);

    return (
        <>
            <div className="flex flex-col w-full">
                {loading || saving ? (
                    <>
                        <TableLoading />
                    </>
                ) : (
                    <>
                        <div className="max-w-full w-full overflow-x-auto">
                            <section>
                                <Table
                                    className="editable_table"
                                    size={'large'}
                                    bordered={false}
                                    columns={defaultColumns}
                                    dataSource={dataSource}
                                    rowKey={'rdnId'}
                                    pagination={{
                                        defaultPageSize: 10,
                                        hideOnSinglePage: true,
                                        pageSizeOptions: [10, 20, 50, 100],
                                    }}
                                    scroll={{
                                        x: 1000,
                                    }}
                                    footer={() => {
                                        return (
                                            <>
                                                <div className="flex items-center w-full justify-between gap-[1.25rem] p-[1rem]">
                                                    <button type="button" onClick={handleOpen} className="flex items-center gap-[.5rem]">
                                                        <div className="black-round-btn">
                                                            <svg className="z-[100]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path
                                                                    d="M11.9998 4.7998L11.9998 19.1998M19.1998 11.9998L4.7998 11.9998"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </div>

                                                        <span className="role_btn_text">Add</span>
                                                    </button>
                                                </div>
                                            </>
                                        );
                                    }}
                                />
                            </section>
                        </div>
                    </>
                )}

                <div className="py-[2.44rem] w-full flex justify-center items-center">
                    <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
                        <button disabled={saving} onClick={() => handleSubmitStep()} type="button" className="cstm-btn-2">
                            {saving ? <Spin /> : 'Next'}
                        </button>

                        <button onClick={() => handleActiveStep(0)} type="button" className="cstm-btn">
                            Previous
                        </button>
                    </div>
                </div>
            </div>

            <CreateGroupContributionTypeModal open={open} handleCancel={handleClose} activeContributionType={activeContributionType} custId={newChamaData?.custId} />
        </>
    );
}
