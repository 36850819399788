import { Divider, Form, Input, Select, Space, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { groupMandates } from '../../../data';
import FileUploader from '../../../components/FileUploader';
import { useDispatch, useSelector } from 'react-redux';
import { handleEditChamaData, handleUpdateActiveChama } from '../../../features/obj/objSlice';
import useFetchDispatch from '../../../custom_hooks/useFetchDispatch';
import { fetchGroupMembers } from '../../../features/fetch/fetchSlice';
import { applicationUrl, capitalize, customToast, formatFilePath } from '../../../utils';
import CardLoading from '../../../components/CardLoading';
import { save, saveFile } from '../../../features/save/saveSlice';

export default function ChamaGroupForm() {
    const dispatch = useDispatch();

    const { isLoading } = useFetchDispatch(fetchGroupMembers);

    const { members } = useSelector((state) => state.fetch);
    const { saving } = useSelector((state) => state.save);
    const { activeChama } = useSelector((state) => state.obj);

    const [logo, setlogo] = useState(activeChama?.custProfileImage ?? '');

    async function handleUploadProfile(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            return customToast({
                content: `File must be smaller than 20MBs`,
                bdColor: '',
                id: 72782762691,
            });
        }

        const res = await dispatch(saveFile(file));

        if (res?.payload?.success) {
            const saveObj = {
                custId: activeChama?.custId,
                custProfileImage: res?.payload?.data?.result,
                url: applicationUrl().url,
                saveUrl: '/api/v1/customers',
            };

            const response = await dispatch(save(saveObj));

            if (response?.payload?.success) {
                await setlogo(res?.payload?.data?.result);
                await dispatch(
                    handleUpdateActiveChama({
                        custProfileImage: res?.payload?.data?.result,
                    })
                );
            } else {
                await customToast({
                    content: res?.payload?.messages?.message ?? 'Could not upload file. Please try again later',
                    bdColor: 'error',
                    id: 736353333333,
                });
            }
        } else {
            await customToast({
                content: res?.payload?.messages?.message ?? 'Could not upload file. Please try again later',
                bdColor: 'error',
                id: 112233445566778899,
            });
        }
    }

    const handleSuccess = (response) => {
        setlogo(response?.data?.result);

        dispatch(
            handleEditChamaData({
                [response.fileName]: response?.data?.result,
            })
        );
    };

    useEffect(() => {}, [members]);

    if (isLoading) return <CardLoading />;

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-[1.5rem] items-center">
                    <Form.Item
                        label="Group name"
                        name="custName"
                        rules={[
                            {
                                required: true,
                                message: 'Field is required',
                            },
                        ]}
                    >
                        <Input className="input" />
                    </Form.Item>

                    <Form.Item label="Group logo">
                        {/* <FileUploader fileName={logo?.split('_').pop()} name="custProfileImage" onSuccess={handleSuccess} /> */}
                        <label htmlFor="custIcon" className="w-full flex items-center justify-between input pointer">
                            <div className="">
                                {saving ? (
                                    <div className="flex items-center gap-[.75rem]">
                                        <Spin className="blu_spin" /> <span className="animate-pulse">Uploading file...</span>
                                    </div>
                                ) : logo.length ? (
                                    logo?.split('_').pop()
                                ) : null}
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M13.5329 8.1639L8.24886 13.448C6.81064 14.8862 4.65369 15.056 3.18733 13.5897C1.74911 12.1515 1.93603 10.0679 3.40239 8.60152L9.34226 2.66165C10.2513 1.75256 11.7149 1.75256 12.624 2.66165C13.5331 3.57074 13.5331 5.03428 12.624 5.94337L6.57981 11.9875C6.1267 12.4406 5.39206 12.4406 4.93895 11.9875C4.48584 11.5344 4.48584 10.7998 4.93895 10.3467L10.3273 4.95829"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                            </svg>
                            <input
                                className="!hidden"
                                onChange={(e) => handleUploadProfile(e.target.files[0])}
                                type="file"
                                name=""
                                id="custIcon"
                                accept=".png, .jpg, .jpeg"
                                hidden
                            />
                        </label>
                    </Form.Item>
                </div>

                <Form.Item
                    label="Group description"
                    name="custGroupDescription"
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <TextArea rows={3} className="text_area" />
                </Form.Item>

                <Form.Item
                    label="Administrator"
                    // name="recurGpmId"
                    rules={[
                        {
                            required: false,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Select
                        disabled
                        showSearch
                        suffixIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path
                                    d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                                    fill="#212121"
                                />
                            </svg>
                        }
                        options={members?.map((item) => {
                            return {
                                label: item?.gpmName + `(${capitalize(item?.gpmRoleName)})`,
                                value: item?.gpmId,
                            };
                        })}
                    />
                </Form.Item>

                <div className="grid grid-cols-1 xl:grid-cols-2 gap-[1.5rem] items-center">
                    <Form.Item
                        label="Group mandate"
                        name="custGroupMandate"
                        rules={[
                            {
                                required: true,
                                message: 'Field is required',
                            },
                        ]}
                    >
                        <Select
                            suffixIcon={
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path
                                        d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                                        fill="#212121"
                                    />
                                </svg>
                            }
                            dropdownRender={(menu) => (
                                <>
                                    <Space
                                        style={{
                                            padding: '.62rem 1rem',
                                        }}
                                    >
                                        <span className="select_title">Select number of signatories needed for approvals</span>
                                    </Space>
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    {menu}
                                </>
                            )}
                            options={groupMandates.map((item, index) => {
                                return {
                                    label: (
                                        <div key={index}>
                                            <span>{item.label}</span>
                                        </div>
                                    ),
                                    value: item.value,
                                };
                            })}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Signatories"
                        // name="recurGpmId"
                        rules={[
                            {
                                required: false,
                                message: 'Field is required',
                            },
                        ]}
                    >
                        <Select
                            disabled
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
                            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                            suffixIcon={
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path
                                        d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                                        fill="#212121"
                                    />
                                </svg>
                            }
                            options={members?.map((item) => {
                                return {
                                    label: item?.gpmName + `(${capitalize(item?.gpmRoleName)})`,
                                    value: item?.gpmId,
                                };
                            })}
                        />
                    </Form.Item>
                </div>

                <Form.Item
                    label="Maximum no. of seats per member"
                    name="custSeat"
                    rules={[
                        {
                            required: true,
                            message: 'Field is required',
                        },
                    ]}
                >
                    <Select
                        suffixIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path
                                    d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                                    fill="#212121"
                                />
                            </svg>
                        }
                        options={[1, 2, 3, 4, 5].map((item, index) => {
                            return {
                                label: (
                                    <div key={index}>
                                        <span>{item}</span>
                                    </div>
                                ),
                                value: item,
                            };
                        })}
                    />
                </Form.Item>
            </div>
        </>
    );
}
