import React from 'react';
import networkLess from "../assets/img/networkLess.jpg"
import SubHeader from '../layout/SubHeader';

export default function NoNetwork() {
	return (
        <>
         <SubHeader />
         <div
			style={{
				border: '1px solid var(--Tile-stroke, #E2E9EE)',
				background: '#fff',
			}}
			className='w-full h-[92vh] flex justify-center items-center'>
                <img src={networkLess} alt="no network" />
            </div>
        </>
		
	);
}
