import { Input } from 'antd';

export default function FilterSearch({ text, searchInput, handleSearch }) {
    return (
        <>
            <div className="search_filter">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M16.9284 17.0416L20.4016 20.4016M11.4016 7.20156C13.3898 7.20156 15.0016 8.81334 15.0016 10.8016M19.2816 11.4416C19.2816 15.7715 15.7715 19.2816 11.4416 19.2816C7.11165 19.2816 3.60156 15.7715 3.60156 11.4416C3.60156 7.11165 7.11165 3.60156 11.4416 3.60156C15.7715 3.60156 19.2816 7.11165 19.2816 11.4416Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />
                </svg>
                <Input onChange={(e) => handleSearch(e.target.value)} value={searchInput} placeholder={text} className="input-borderless" type="text" />
            </div>
        </>
    );
}
