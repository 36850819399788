import { Spin, Table } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { capitalize, formatMoney } from '../../../utils';
import STKPushSentModal from '../../../components/STKPushSentModal';
import { useDispatch, useSelector } from 'react-redux';
import { debitNoteTypeColor } from '../../../data';
import StatementFilter from '../../statements/StatementFilter';
import Search from '../../../components/Search';
import useDynamicFilter from '../../../custom_hooks/useDynamicFilter';
import { clearFilters, fetchScheduleFilter } from '../../../features/filters/filterSlice';
import TableLoading from '../../../components/TableLoading';

const filterOptions = [
    {
        key: '0',
        category: 'Filter by type',
        options: ['All', 'SAVING', 'LOANS'],
    },
    {
        key: '1',
        category: 'Filter by activity',
        options: ['All', 'OUTSTANDING_BALANCE', 'ARREARS', 'Penalty'],
    },
];

export default function ActivityArrearsTable({ data, handleFetch }) {
    const dispatch = useDispatch();

    const { moneying } = useSelector((state) => state.money);
    const { fiLoading, scheduleFilters, filters } = useSelector((state) => state.filter);

    const [open, setopen] = useState(false);
    const [selectedItem, setselectedItem] = useState({});
    const [dataSource, setdataSource] = useState(data);

    const [searchInput, setsearchInput] = useState('');

    const filterFns = useMemo(() => {
        const search = searchInput.toUpperCase();
        return [
            (item) => {
                if (!search) return item;
                const { schContAmount, createdDate, schParticular, schSourceType, schDescription } = item || {};
                return (
                    String(schContAmount).includes(search) ||
                    moment(createdDate).format('Do MMM YYYY').toUpperCase().includes(search?.toUpperCase()) ||
                    String(schParticular).toUpperCase().includes(search?.toUpperCase()) ||
                    capitalize(schSourceType?.split('_').join(' ')).toUpperCase().includes(search?.toUpperCase()) ||
                    capitalize(schDescription?.split('_').join(' ')).toUpperCase().includes(search?.toUpperCase())
                );
            },
        ];
    }, [searchInput]);

    const filteredData = useDynamicFilter(dataSource, filterFns);

    const handleSearch = (val) => setsearchInput(val);

    const handleApplyFilters = async (filters) => {
        const preparedFilters = {
            ...filters,
            filters: {
                ...filters?.filters,
                'Filter by activity': filters?.filters['Filter by activity'] || {},
                'Filter by type': filters?.filters['Filter by type'] || {},
            },
        };
        delete preparedFilters.filters?.dateRange;
        await dispatch(fetchScheduleFilter(preparedFilters));
    };

    const handleClearFilters = () => dispatch(clearFilters());

    const hasFiltersApplied = Object.keys(filters || {}).length > 0;
    const noRecords = hasFiltersApplied && (!scheduleFilters || scheduleFilters?.length === 0);

    useEffect(() => {
        dispatch(clearFilters());
    }, [dispatch]);

    function handleCancel() {
        setopen(false);
        setselectedItem({});
    }

    async function handleAction(item) {
        await setselectedItem(item);
        await setopen(true);
    }

    useEffect(() => {
        setdataSource(data);
    }, [data]);

    useEffect(() => {}, [dataSource, scheduleFilters, filters]);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'schCreatedDate',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        },
        {
            title: 'Source',
            dataIndex: 'schParticular',
            render: (item) => {
                return (
                    <div>
                        <span>{capitalize(item?.split('_').join(' '))}</span>
                    </div>
                );
            },
        },
        {
            title: 'Amount (KES)',
            dataIndex: 'schContAmount',
            render: (item) => <button type="button">{formatMoney(item, 'KES')}</button>,
        },
        {
            title: 'Type',
            dataIndex: 'schSourceType',
            render: (item) => {
                const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6';

                return (
                    <div
                        style={{
                            background: roleColor,
                        }}
                        className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                            <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                        </svg>
                        <span>{capitalize(item?.split('_')?.join(' '))}</span>
                    </div>
                );
            },
        },

        {
            title: 'Description',
            dataIndex: 'schDescription',
            render: (item) => <span>{item ?? ' - '}</span>,
        },
        
        {
            title: 'Action',
            render: (item) => (
                <button disabled={moneying} onClick={() => handleAction(item)} type="button">
                    {moneying && selectedItem === item ? <Spin className="blu_spin" /> : 'Pay'}
                </button>
            ),
        },
    ];

    return (
        <>
            <div className="flex items-center gap-[1rem] w-full">
                <div className="w-[13.625rem]">
                    <Search handleSearch={handleSearch} searchInput={searchInput} readOnly text="Search" />
                </div>
                <div className="w-[12.5rem]">
                    <StatementFilter disabled filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} handleClearFilters={handleClearFilters} />
                </div>
            </div>

            {moneying || fiLoading ? (
                <TableLoading />
            ) : noRecords ? (
                <Table rowKey="schId" className="mt-[1.5rem] !w-full" scroll={{ x: 1000 }} columns={columns} dataSource={[]} />
            ) : (
                <Table
                    rowKey="schId"
                    className="mt-[1.5rem] !w-full"
                    pagination={{
                        defaultPageSize: 7,
                        hideOnSinglePage: true,
                        pageSizeOptions: [10, 20, 50, 100],
                    }}
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={scheduleFilters?.length ? scheduleFilters : filteredData}
                />
            )}
            <STKPushSentModal handleFetch={handleFetch} open={open} handleCancel={handleCancel} selectedItem={selectedItem} />
        </>
    );
}
