import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
    fiLoading: false,
    filters: {},
    transactionFilters: [],
    scheduleFilters: [],
    withdrawalFilters: [],
};

export const fetchTransactionFilter = createAsyncThunk('filterSlice/fetchTransactionFilter', async (data) => {
    const res = await axiosInstance.post(`${url}/api/v1/transactions/filter`, data).then((res) => res.data?.data?.result);
    return res;
});

export const fetchScheduleFilter = createAsyncThunk('filterSlice/fetchScheduleFilter', async (data) => {
    const res = await axiosInstance.post(`${url}/api/v1/schedule/filter`, data).then((res) => res.data?.data?.result);
    return res;
});

export const fetchWithdrawalFilter = createAsyncThunk('filterSlice/fetchWithdrawalFilter', async (data) => {
    const res = await axiosInstance.post(`${url}/api/withdrawals/filter`, data).then((res) => res.data?.data?.result);
    return res;
});

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        handleFilters: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        clearFilters: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchWithdrawalFilter.pending, (state) => {
                state.fiLoading = true;
            })
            .addCase(fetchWithdrawalFilter.fulfilled, (state, action) => {
                state.fiLoading = false;
                state.withdrawalFilters = action.payload;
            })
            .addCase(fetchWithdrawalFilter.rejected, (state) => {
                state.fiLoading = false;
            })

            .addCase(fetchScheduleFilter.pending, (state) => {
                state.fiLoading = true;
            })
            .addCase(fetchScheduleFilter.fulfilled, (state, action) => {
                state.fiLoading = false;
                state.scheduleFilters = action.payload;
            })
            .addCase(fetchScheduleFilter.rejected, (state) => {
                state.fiLoading = false;
            })

            .addCase(fetchTransactionFilter.pending, (state) => {
                state.fiLoading = true;
            })
            .addCase(fetchTransactionFilter.fulfilled, (state, action) => {
                state.fiLoading = false;
                state.transactionFilters = action.payload;
            })
            .addCase(fetchTransactionFilter.rejected, (state) => {
                state.fiLoading = false;
            });
    },
});

export default filterSlice.reducer;
export const { clearFilters, handleFilters } = filterSlice.actions;
