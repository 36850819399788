import { createHashRouter } from 'react-router-dom';
import Login from './pages/auth/Login';
import { ProtectedRoute } from './ProtectedRoute';
import Root from './Root';
import Register from './pages/auth/Register';
import Onboarding from './pages/onboarding/Onboarding';
import { OnboardingRoute } from './OnboardingRout';
import OnboardingOptions from './pages/onboarding/OnboardingOptions';
import MainHome from './pages/home/MainHome';
import Statements from './pages/statements/Statements';
import { FullPageRoute } from './FullPageRoute';
import NewChama from './pages/chama/NewChama';
import ChamaFeatures from './pages/chama/ChamaFeatures';
import ActivutyOverview from './pages/activities/ActivityOverview';
import GroupStatement from './pages/contributions/GroupStatement';
import GroupOverview from './pages/group/GroupOverview';
import MemberBalance from './pages/members/MembersBalance';
import GroupContributionHistory from './pages/group/GroupContributionHistory';
import MembersOverview from './pages/members/MembersOverview';
import MembersPenalties from './pages/members/MembersPenalties';
import AccountSummary from './pages/accounts/AccountSummary';
import ActivityHistory from './pages/activities/ActivityHistory';
import ActivityStatement from './pages/activities/ActivityStatement';
import ActivityArrears from './pages/activities/ActivtyArrears';
import NewUserInvite from './pages/invites/NewUserInvite';
import MemberDetails from './pages/members/MemberDetails';
import JoinChamaGroup from './pages/chama/JoinChamaGroup';
import MemberInvite from './pages/members/MemberInvite';
import ForgotPassword from './pages/auth/ForgotPassword';
import StatementsSummary from './pages/statements/StatementsSummary';
import ContributionStatements from './pages/statements/ContributionStatements';
import MembersApproval from './pages/approvals/MembersApproval';
import OpeningBalanceApprovals from './pages/approvals/OpeningBalanceApprovals';
import GroupContributionTypes from './pages/group/GroupContributionTypes';
import ContributionTypeDetails from './pages/contribution_type_details/ContributionTypeDetails';
import DebitNotes from './pages/debit_notes/DebitNotes';
import Settlement from './pages/settlement/Settlement';
import Disbursement from './pages/disbursement/Disbursement';
import Reimbursement from './pages/reimbursement/Reimbursement';
import PathValidator from './PathValidator';
import ArrearsStatements from './pages/statements/ArrearsStatements';
import SettlementApprovals from './pages/approvals/SettlementApprovals';
import AccountsTransfer from './pages/accounts_transfer/AccountsTransfer';
import AccountTransferApprovals from './pages/approvals/AccountTransferApprovals';
import DisbursementApprovals from './pages/approvals/DisbursementApprovals';
import ReimbursementApprovals from './pages/approvals/ReimbursementApprovals';
import LandingPage from './pages/landing_page/LandingPage';
import ChamaSettings from './pages/settings/ChamaSettings';
import SettlementAccountApprovals from './pages/approvals/SettlementAccountApprovals';
import ProfileSettings from './pages/profile/Profile';
import PrivacyPolicy from './pages/privacy_policy/PrivacyPolicy';

export const router = createHashRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '/',
                element: <LandingPage />,
                index: true,
            },
            {
                path: '/home',
                element: <LandingPage />,
                index: true,
            },
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy />
            },
            {
                path: '/registration',
                element: <Register />,
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />,
            },
            {
                path: '/new-user/chama-invite',
                element: <NewUserInvite />,
            },
            {
                element: <OnboardingRoute />,
                children: [
                    {
                        path: '/onboarding',
                        element: <Onboarding />,
                    },
                    {
                        path: '/onboarding-options',
                        element: <OnboardingOptions />,
                    },
                    {
                        path: '/chama-features',
                        element: <ChamaFeatures />,
                    },
                ],
            },
            {
                element: <FullPageRoute />,
                children: [
                    {
                        path: '/new/chama-features',
                        element: <ChamaFeatures />,
                    },
                    {
                        path: '/new/chama-group',
                        element: <NewChama />,
                    },
                ],
            },
            {
                element: (
                    <PathValidator>
                        <ProtectedRoute />
                    </PathValidator>
                ),
                children: [
                    {
                        path: '/dashboard',
                        element: <MainHome />,
                    },
                    {
                        path: '/profile/settings',
                        element: <ProfileSettings />,
                    },
                    {
                        path: '/join-chama',
                        element: <JoinChamaGroup />,
                    },
                    {
                        path: '/statements/summary',
                        element: <StatementsSummary />,
                    },
                    {
                        path: '/group-activity/statements/contributions',
                        element: <ContributionStatements />,
                    },
                    {
                        path: '/group-activity/statements',
                        element: <Statements />,
                    },
                    {
                        path: '/group-activity/statements/arrears',
                        element: <ArrearsStatements />,
                    },
                    {
                        path: '/group/overview',
                        element: <GroupOverview />,
                    },
                    {
                        path: '/group/contribution-history',
                        element: <GroupContributionHistory />,
                    },
                    {
                        path: '/activity/overview',
                        element: <ActivutyOverview />,
                    },
                    {
                        path: '/activity/history',
                        element: <ActivityHistory />,
                    },
                    {
                        path: '/activity/statements',
                        element: <ActivityStatement />,
                    },
                    {
                        path: '/activity/payment',
                        element: <ActivityArrears />,
                    },
                    {
                        path: '/activity/reimbursement',
                        element: <Reimbursement />,
                    },
                    {
                        path: '/contributions/group-statements',
                        element: <GroupStatement />,
                    },
                    {
                        path: '/contribution/types',
                        element: <GroupContributionTypes />,
                    },
                    {
                        path: '/contribution/types/overview/:name',
                        element: <ContributionTypeDetails />,
                    },
                    {
                        path: '/debit/notes',
                        element: <DebitNotes />,
                    },
                    {
                        path: '/chama/settings',
                        element: <ChamaSettings />,
                    },
                    {
                        path: '/funds-transfer/settlement',
                        element: <Settlement />,
                    },
                    {
                        path: '/funds-transfer/disbursement',
                        element: <Disbursement />,
                    },
                    {
                        path: '/funds-transfer/accounts-transfer',
                        element: <AccountsTransfer />,
                    },
                    {
                        path: '/members/overview',
                        element: <MembersOverview />,
                    },
                    {
                        path: '/members/invite',
                        element: <MemberInvite />,
                    },
                    {
                        path: '/members/balance',
                        element: <MemberBalance />,
                    },
                    {
                        path: '/members/penalties',
                        element: <MembersPenalties />,
                    },
                    // {
                    //     path: '/members/outstanding-balance',
                    //     element: <MembersOutstandingBalance />,
                    // },
                    {
                        path: '/members/:name',
                        element: <MemberDetails />,
                    },
                    {
                        path: '/account/summary',
                        element: <AccountSummary />,
                    },
                    {
                        path: '/approvals/members',
                        element: <MembersApproval />,
                    },
                    {
                        path: '/approvals/opening-balance',
                        element: <OpeningBalanceApprovals />,
                    },
                    {
                        path: '/approvals/settlement',
                        element: <SettlementApprovals />,
                    },
                    {
                        path: '/approvals/disbursements',
                        element: <DisbursementApprovals />,
                    },
                    {
                        path: '/approvals/reimbursement',
                        element: <ReimbursementApprovals />,
                    },
                    {
                        path: '/approvals/account-transfer',
                        element: <AccountTransferApprovals />,
                    },
                    {
                        path: '/approvals/settlement-accounts',
                        element: <SettlementAccountApprovals />,
                    },
                ],
            },
            {
                path: '*',
                element: <p>404 - Error: Page not found</p>,
            },
        ],
    },
]);
